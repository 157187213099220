@font-face {
    font-family: "Moderat";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url("../assets/fonts/Moderat-Thin.eot");
    src: url("../assets/fonts/Moderat-Thin.eot?#iefix")
        format("embedded-opentype"),
      url("../assets/fonts/Moderat-Thin.woff2") format("woff2"),
      url("../assets/fonts/Moderat-Thin.woff") format("woff"),
      url("../assets/fonts/Moderat-Thin.ttf") format("truetype"),
      url("../assets/fonts/Moderat-Thin.svg#Moderat-Thin") format("svg");
  }
  @font-face {
    font-family: "Moderat";
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url("../assets/fonts/Moderat-Thin-Italic.eot");
    src: url("../assets/fonts/Moderat-Thin-Italic.eot?#iefix")
        format("embedded-opentype"),
      url("../assets/fonts/Moderat-Thin-Italic.woff2") format("woff2"),
      url("../assets/fonts/Moderat-Thin-Italic.woff") format("woff"),
      url("../assets/fonts/Moderat-Thin-Italic.ttf") format("truetype"),
      url("../assets/fonts/Moderat-Thin-Italic.svg#Moderat-Thin-Italic")
        format("svg");
  }
  @font-face {
    font-family: "Moderat";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("../assets/fonts/Moderat-Light.eot");
    src: url("../assets/fonts/Moderat-Light.eot?#iefix")
        format("embedded-opentype"),
      url("../assets/fonts/Moderat-Light.woff2") format("woff2"),
      url("../assets/fonts/Moderat-Light.woff") format("woff"),
      url("../assets/fonts/Moderat-Light.ttf") format("truetype"),
      url("../assets/fonts/Moderat-Light.svg#Moderat-Light") format("svg");
  }
  @font-face {
    font-family: "Moderat";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("../assets/fonts/Moderat-Light-Italic.eot");
    src: url("../assets/fonts/Moderat-Light-Italic.eot?#iefix")
        format("embedded-opentype"),
      url("../assets/fonts/Moderat-Light-Italic.woff2") format("woff2"),
      url("../assets/fonts/Moderat-Light-Italic.woff") format("woff"),
      url("../assets/fonts/Moderat-Light-Italic.ttf") format("truetype"),
      url("../assets/fonts/Moderat-Light-Italic.svg#Moderat-Light-Italic")
        format("svg");
  }
  @font-face {
    font-family: "Moderat";
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url("../assets/fonts/Moderat-Regular.eot");
    src: url("../assets/fonts/Moderat-Regular.eot?#iefix")
        format("embedded-opentype"),
      url("../assets/fonts/Moderat-Regular.woff2") format("woff2"),
      url("../assets/fonts/Moderat-Regular.woff") format("woff"),
      url("../assets/fonts/Moderat-Regular.ttf") format("truetype"),
      url("../assets/fonts/Moderat-Regular.svg#Moderat-Regular") format("svg");
  }
  @font-face {
    font-family: "Moderat";
    font-style: italic;
    font-weight: normal;
    font-display: swap;
    src: url("../assets/fonts/Moderat-Regular-Italic.eot");
    src: url("../assets/fonts/Moderat-Regular-Italic.eot?#iefix")
        format("embedded-opentype"),
      url("../assets/fonts/Moderat-Regular-Italic.woff2") format("woff2"),
      url("../assets/fonts/Moderat-Regular-Italic.woff") format("woff"),
      url("../assets/fonts/Moderat-Regular-Italic.ttf") format("truetype"),
      url("../assets/fonts/Moderat-Regular-Italic.svg#Moderat-Regular-Italic")
        format("svg");
  }
  @font-face {
    font-family: "Moderat";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("../assets/fonts/Moderat-Medium.eot");
    src: url("../assets/fonts/Moderat-Medium.eot?#iefix")
        format("embedded-opentype"),
      url("../assets/fonts/Moderat-Medium.woff2") format("woff2"),
      url("../assets/fonts/Moderat-Medium.woff") format("woff"),
      url("../assets/fonts/Moderat-Medium.ttf") format("truetype"),
      url("../assets/fonts/Moderat-Medium.svg#Moderat-Medium") format("svg");
  }
  @font-face {
    font-family: "Moderat";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url("../assets/fonts/Moderat-Medium-Italic.eot");
    src: url("../assets/fonts/Moderat-Medium-Italic.eot?#iefix")
        format("embedded-opentype"),
      url("../assets/fonts/Moderat-Medium-Italic.woff2") format("woff2"),
      url("../assets/fonts/Moderat-Medium-Italic.woff") format("woff"),
      url("../assets/fonts/Moderat-Medium-Italic.ttf") format("truetype"),
      url("../assets/fonts/Moderat-Medium-Italic.svg#Moderat-Medium-Italic")
        format("svg");
  }
  @font-face {
    font-family: "Moderat";
    font-style: normal;
    font-weight: bold;
    font-display: swap;
    src: url("../assets/fonts/Moderat-Bold.eot");
    src: url("../assets/fonts/Moderat-Bold.eot?#iefix")
        format("embedded-opentype"),
      url("../assets/fonts/Moderat-Bold.woff2") format("woff2"),
      url("../assets/fonts/Moderat-Bold.woff") format("woff"),
      url("../assets/fonts/Moderat-Bold.ttf") format("truetype"),
      url("../assets/fonts/Moderat-Bold.svg#Moderat-Bold") format("svg");
  }
  @font-face {
    font-family: "Moderat";
    font-style: italic;
    font-weight: bold;
    font-display: swap;
    src: url("../assets/fonts/Moderat-Bold-Italic.eot");
    src: url("../assets/fonts/Moderat-Bold-Italic.eot?#iefix")
        format("embedded-opentype"),
      url("../assets/fonts/Moderat-Bold-Italic.woff2") format("woff2"),
      url("../assets/fonts/Moderat-Bold-Italic.woff") format("woff"),
      url("../assets/fonts/Moderat-Bold-Italic.ttf") format("truetype"),
      url("../assets/fonts/Moderat-Bold-Italic.svg#Moderat-Bold-Italic")
        format("svg");
  }
  @font-face {
    font-family: "Moderat";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("../assets/fonts/Moderat-Black.eot");
    src: url("../assets/fonts/Moderat-Black.eot?#iefix")
        format("embedded-opentype"),
      url("../assets/fonts/Moderat-Black.woff2") format("woff2"),
      url("../assets/fonts/Moderat-Black.woff") format("woff"),
      url("../assets/fonts/Moderat-Black.ttf") format("truetype"),
      url("../assets/fonts/Moderat-Black.svg#Moderat-Black") format("svg");
  }
  @font-face {
    font-family: "Moderat";
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url("../assets/fonts/Moderat-Black-Italic.eot");
    src: url("../assets/fonts/Moderat-Black-Italic.eot?#iefix")
        format("embedded-opentype"),
      url("../assets/fonts/Moderat-Black-Italic.woff2") format("woff2"),
      url("../assets/fonts/Moderat-Black-Italic.woff") format("woff"),
      url("../assets/fonts/Moderat-Black-Italic.ttf") format("truetype"),
      url("../assets/fonts/Moderat-Black-Italic.svg#Moderat-Black-Italic")
        format("svg");
  }
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    font-family: "Moderat";
    font-size: 16px;
    font-weight: normal;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: "icomoon";
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: url("../assets/fonts/icomoon.eot"),
      url("../assets/fonts/icomoon.eot") format("embedded-opentype"),
      url("../assets/fonts/icomoon.ttf") format("truetype"),
      url("../assets/fonts/icomoon.woff") format("woff"),
      url("../assets/fonts/icomoon.svg#icomoon") format("svg");
  }